import React from 'react';
import { Link } from 'gatsby'
import Map from '../components/Map'
import IntakeForm from '../data/intake_form.pdf'
import '../scss/style.scss'
import logo from '../img/logo.svg';

const FormSuccess = props => (
  <div className="form-complete-body">
    <div className="form-complete-screen">
      <div className="logo-large">
        <Link to="/">
          <img alt="mBody Health Akron" src={logo} />
        </Link>
      </div>
      <h3>Thanks! We've received your message.</h3>
      <h5 style={{marginBottom: '1em'}}>
        Our office staff will review your inquiry
        and get back to you within the next 48 hours.
      </h5>
      <h5>Here's what you'll need for your first visit:</h5>
      <ul className="form-complete-list">
        <li className="form-complete-list-item">1) A completed <strong><a href={IntakeForm} target="_blank" rel="noopener noreferrer" style={{color: "#603053"}}>intake form.</a></strong> (New Patients Only)</li>
        <li className="form-complete-list-item">2) Proof of coverage (if paying through insurance)</li>
      </ul>
      <h5 style={{marginBottom: '1em'}} >Get Directions to Our Office:</h5>
      <Map />
      <div style={{marginTop: '4rem'}}>
        <Link to="/" ><button className="button button-primary">Great! Take me back home</button></Link>
      </div>
    </div>
  </div>
)

export default FormSuccess;
